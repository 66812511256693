<template>
  <div>
    <div class="in-banner">
      <img style="width: 100%" :src="require('@/assets/wechat/about_company.jpg')" alt="关于我们">
    </div>
    <!-- 公司简介-->
    <div class="ab">
      <div class="w90">
        <div class="title3">
          <h5>公司简介</h5>
          <h6>Company Profile</h6>
        </div>
        <div class="h30"></div>
        <div class="txt">
          <p style="line-height: 1.5em; margin-bottom: 15px; text-align: justify; text-indent: 2em;"
             v-for="(item,index) in this.contents" :key="index">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="w90">
        <div class="title3">
          <h5>产品简介</h5>
          <h6>Product Introduction</h6>
        </div>
        <div class="h30"></div>
        <div style="display: flex; flex-flow: row wrap; align-items: center; justify-content: center; flex-wrap: wrap;">
          <product_item style="justify-content: center" @clickDetail="previewImages"
                        :product-basic-info="productBasicInfo"
                        :me_style="{width:'150px',height:'150px'}"/>
        </div>
      </div>
    </div>
    <!--    企业文化-->
    <div class="culture">
      <div class="bk" id="culture"></div>
      <div class="w90">
        <div class="h30"></div>
        <div class="title3">
          <h5>企业文化</h5>
          <h6>corporate culture</h6>
        </div>
        <div class="h30"></div>
        <ul>
          <li>
            <div class="ico"><img :src="require('@/assets/wechat/5e702a71b85a5.png')"></div>
            <h5>企业愿景</h5>
            <h6><p></p>
              <p>致力成为跨境电商平台</p>
              <p>最具竞争力企业</p>
            </h6>
          </li>
          <li>
            <div class="ico"><img :src="require('@/assets/wechat/5e702bc279a67.png')"></div>
            <h5>企业使命</h5>
            <h6><p> 为客户创造价值</p>
              <p>实现员工梦想</p>
              <p>对社会做出贡献</p>
            </h6>
          </li>
          <li>
            <div class="ico"><img :src="require('@/assets/wechat/5e702be313281.png')"></div>
            <h5>企业价值观</h5>
            <h6><p> 以人为本</p>
              <p>齐心协力</p>
              <p>艰苦奋斗</p>
            </h6>
          </li>
          <li>
            <div class="ico"><img :src="require('@/assets/wechat/5e702b2ca6249.png')"></div>
            <h5>企业精神</h5>
            <h6> 忠诚 务实 创新</h6>

          </li>
          <li>
            <div class="ico"><img :src="require('@/assets/wechat/5e702a906093b.png')"></div>
            <h5>经营理念</h5>
            <h6> 诚信 服务 共赢</h6>
          </li>
        </ul>
        <div class="h80"></div>
      </div>
    </div>
    <div class="footer2">
      <div class="w90">
        <span>© 成都市锦荣源纤维科技有限公司 蜀ICP备2021009798号</span>
      </div>
    </div>
    <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        :before-close="handlerClose"
        width="90%"

    >
      <div style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center">
        <el-image v-for="(item,index) in this.detailInfo.productImgs" :preview-src-list="srcAllFile" fit="cover"
                  style="width: 40%;margin: 10px 5px"
                  :src="item.fileUrl" :key="index" lazy @click="handlerClose"></el-image>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {httpAction} from "@/api/manage";
import Product_item from "@/components/product_item";

export default {
  name: "index",
  components: {Product_item},
  computed: {
    srcAllFile() {
      let temp = []
      this.detailInfo.productImgs.forEach(item => {
        temp.push(item.fileUrl)
      })
      return temp
    }
  },
  data() {
    return {
      urls: {
        companyInfo: "/companyManage/getCompanyInfo",
        productBasicUrl: "/productManage/queryProductBasicList",
        getProductDetail: "/productManage/findDetailById"
      },
      showIndex: 0,
      images: [],
      classObj: {
        prev: 'swiper-button-prev',
        next: 'swiper-button-next',
        dis: 'swiper-button-disabled',
        prevdis: true,
        nextdis: false
      },
      dialogVisible: false,
      contents: [],
      productBasicInfo: [],
      companyHonorImg: [
        require('@/assets/wechat/honorimg/1.png'),
        require('@/assets/wechat/honorimg/2.png'),
        require('@/assets/wechat/honorimg/3.png'),
        require('@/assets/wechat/honorimg/4.png'),
        require('@/assets/wechat/honorimg/5.png'),
        require('@/assets/wechat/honorimg/6.png'),
        require('@/assets/wechat/honorimg/7.png'),
        require('@/assets/wechat/honorimg/8.png'),
        require('@/assets/wechat/honorimg/9.png'),
      ],
      detailInfo: {
        context: "",
        id: "",
        indexImage: "",
        isIndex: "1",
        name: "",
        price: null,
        productDetail: [],
        productImgs: [],
      }
    }
  },
  mounted() {
    this.getContext()
    this.loadProductList()
  },
  methods: {
    loadProductList() {
      httpAction(this.urls.productBasicUrl, {body: {isIndex: 1}}, "post").then(res => {
        if (res.code == 0) {
          this.productBasicInfo = res.data
        }
      })
    },
    handlerClose() {
      this.dialogVisible = false
    },
    previewImages(item) {
      httpAction(this.urls.getProductDetail, {body: item.id}).then(res => {
        if (res.code == 0) {
          this.detailInfo = res.data
          this.dialogVisible = true
        }
      })
    },
    getContext() {
      httpAction(this.urls.companyInfo, {body: {itemValue: "companyOver"}}).then(res => {
        if (res.code == 0) {
          this.contents = res.data.companyOver.split("<p>")
        }
        return httpAction(this.urls.companyInfo, {body: {itemValue: "companyOverImage"}});
      }).then(res1 => {
        if (res1.code == 0) {
          if (res1.data.companyOverImage) {
            this.images = res1.data.companyOverImage.indexOf(",") == -1 ? [res1.data.companyOverImage] : res1.data.companyOverImage.split(",")
          }
        }
      })
    },
    change(type) {
      if (type == 0 && this.showIndex != 0) {
        this.showIndex -= 1
      }
      if (type == 1 && this.showIndex != 3) {
        this.showIndex += 1
      }
      if (this.showIndex == 0) {
        this.classObj.prevdis = true
      } else {
        this.classObj.prevdis = false
      }
      if (this.showIndex == 3) {
        this.classObj.nextdis = true
      } else {
        this.classObj.nextdis = false
      }
    }
  }
}
</script>
<style scoped>
.h80 {
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.h60 {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.h30 {
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.ab {
  width: 100%;
  height: auto;
}

.ab .w90 {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.title3 {
  width: 100%;
  height: auto;
  text-align: center;
}

.title3 h5 {
  font-size: 36px;
  color: #333;
  text-transform: capitalize;
}

.title3 h6 {
  font-size: 15px;
  text-transform: uppercase;
  color: #666;
  font-family: arial;
}

@media only screen and (max-width: 720px) {
  .title3 h5 {
    font-size: 30px;
  }

  .title3 h6 {
    font-size: 14px;
  }
}

.ab .txt {
  width: 100%;
  height: auto;
}

.ab .txt p {
  font-family: 微软雅黑, "Microsoft YaHei";
}

.culture {
  width: 100%;
  height: auto;
  background: url(../../assets/wechat/b011.jpg) center no-repeat;
  background-size: cover;
}

.culture .title3 h5 {
  color: #FFFFFF;
}

.culture .title3 h6 {
  color: #FFFFFF;
}

.culture ul {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 0;
  padding: 0;
}

.culture ul li {
  position: relative;
  width: 27%;
  padding: 0 3%;
  height: auto;
  vertical-align: top;
  display: inline-block;
}

.culture ul li:before {
  width: 1px;
  height: 120px;
  position: absolute;
  right: 0;
  top: 55px;
  content: "";
  background: rgba(255, 255, 255, 0.8);
}

.culture ul li:last-child:before {
  display: none;
}

.culture ul li .ico {
  width: 70px;
  height: auto;
  margin: 0 auto;
}

.culture ul li .ico img {
  width: 100%;
  height: auto;
  display: block;
}

.culture ul li h5 {
  color: #FFFFFF;
  font-size: 20px;
  margin-top: 40px;
}

.culture ul li h6 {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
}

@media only screen and (max-width: 1200px ) {
  .culture ul li .ico {
    width: 60px;
  }

  .culture ul li h5 {
    font-size: 18px;
    margin-top: 30px;
  }

  .culture ul li h6 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .culture ul li .ico {
    width: 50px;
  }
}

@media only screen and (max-width: 900px ) {
  .culture ul li h5 {
    margin-top: 20px;
  }

  .culture ul li {
    width: 27%;
    margin-top: 30px;
  }

  .culture ul li:before {
    display: none;
  }

  .culture ul li:nth-child(4) {
    width: 40%;
  }

  .culture ul li:nth-child(5) {
    width: 40%;
  }
}

@media only screen and (max-width: 720px ) {
  .culture ul li h5 {
    font-size: 16px;
  }

  .culture ul li h6 {
    font-size: 14px;
    margin-top: 5px;
  }

  .culture {
    background: url(../../assets/wechat/b011.jpg) center no-repeat;
    background-size: cover;
  }
}


.w90 {
  width: 90%;
  margin: 0 auto;
  max-width: 1600px;
  height: auto;
}

.events {
  width: 100%;
  height: auto;
  background: url(../../assets/wechat/b013.jpg) center no-repeat;
  background-size: cover;
}

.events .title3 h5 {
  color: #FFFFFF;
  margin: 0;
}

.events .title3 h6 {
  color: #FFFFFF;
  margin: 0;
}

.swiper-wrapper {
  color: #fff;
}

.events .events-txt .con i {
  display: block;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  left: 50%;
  top: 50px;
  transform: translateY(-60%);
}

.events .events-txt .con i:before {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: #FFFFFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: "";
}

.footer2 {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 25px 0;
  background: #333333;
  color: #fff;
  font-family: "微软雅黑", "fut", Arial, "Microsoft Yahei";
}

.swiper-button-prev {
  left: 5%;
  background-image: url(../../assets/wechat/a027.png);
  height: 30px;
  width: 30px;
  background-size: cover;
  position: absolute;
  top: 32px;
}

.swiper-button-next {
  right: 5%;
  background-image: url(../../assets/wechat/a028.png);
  height: 30px;
  width: 30px;
  background-size: cover;
  position: absolute;
  top: 32px;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
</style>
